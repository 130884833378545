#section2 {
  overflow: hidden;
  height: 80vh;
  justify-content: flex-start;
  
  @include mq(1280) {
    flex-direction: row-reverse;
    justify-content: center;
    height: auto;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 110px;

    @include mq(1280) {
      margin-left: 50px;
      margin-bottom: 0;
    }

    h3 {
      font-size: 26px;
      letter-spacing: -1.3px;
      line-height: 33px;
  
      @include mq(480) {
        font-size: 32px;
        line-height: 38px;
      }
  
      @include mq(768) {
      }
  
      @include mq(1280) {
        font-size: 40px;
        text-align: left;
        line-height: 50px;
        margin-top: 0;
      }
    }

    p {
      display: none;
      font-size: 22px;
      color: #666;
      line-height: 30px;
      margin-top: 40px;

      @include mq(1280) {
        display: block;
      }
    }
  }


  .img-phone {
    z-index: 1;
    width: 80%;
    max-width: 280px;

    @include mq(1280) {
      width: 300px;
      margin: 100px 0;
    }
  }

  .bglogo1 {
    position: absolute;
    top: 0;
    right: -80px;
    width: 173px;

    @include mq(1280) {
      top: -3vh;
      right: 10vw;
    }
  }

  .bglogo2 {
    position: absolute;
    bottom: -30px;
    left: -40px;
    width: 140px;

    @include mq(1280) {
      left: 20vw;
      bottom: 10vh;
    }
  }

  .bglogo3 {
    display: none;

    @include mq(1280) {
      display: block;
      position: absolute;
      right: 5vw;
      top: 15vh;
      width: 100px;
    }
  }


}