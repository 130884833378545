#allinansim {
  background-color: #f6f6f6;

  @include mq(1280) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .swiper {
    margin-bottom: 50px;
    height: auto;
    display: flex;
    flex-direction: column;
    
    @include mq(1280) {
      margin-top: 100px;
    }

    img {
      max-width: 260px;
      margin-bottom: 40px;

      @include mq(1280) {
        max-width: 340px;
      }
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      @include mq(1280) {
        flex-direction: row;
      }
    }
  }

  .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include mq(1280) {
      align-items: flex-start;
      margin-right: 250px;
    }

    h3 {
      margin-top: 100px;

      @include mq(1280) {
        font-size: 47px;
      }
    }
  
    .text-with-yellowbar {
      h2 {
        font-size: 34px;
        color: #131313;
        position: relative;
        z-index: 1;

        @include mq(1280) {
          font-size: 61px;
          margin-top: 10px;
          text-align: left;
        }
      }
    
      span.yellow-bar {
        top: -10px;

        @include mq(1280) {
          top: -20px;
          height: 22px;
        }

        &.slide1 {
          width: 128px;

          @include mq(1280) {
            width: 228px;
          }
        }

        &.slide2 {
          @include mq(1280) {
            width: 330px;
          }
        }

        &.slide3 {
          width: 100px;

          @include mq(1280) {
            width: 160px;
          }
        }

        &.slide4 {
          width: 190px;

          @include mq(1280) {
            width: 330px;
          }
        }
      }
    }  
  
    p {
      font-size: 16px;
      color: #666;
      text-align: center;
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 50px;

      @include mq(1280) {
        text-align: left;
        font-size: 23px;
        line-height: 30px;
      }
    }
  }
}