.visible, .invisible {
  opacity: 0.0;
  transition: opacity 1s ease;
}
.visible {
  opacity: 1.0;
}

[data-slideInLeft].visible, [data-slideInLeft].invisible,
[data-slideInRight].visible, [data-slideInRight].invisible ,
[data-slideInTop].visible, [data-slideInTop].invisible ,
[data-slideInBottom].visible, [data-slideInBottom].invisible {
    opacity: 1.0;
    transform: translate(0, 0);
    transition: transform 2s ease, opacity 2s ease;
}

[data-slideInLeft].invisible {
    opacity: 0.0;
    transform: translate(30px, 0);
}

[data-slideInRight].invisible {
    opacity: 0.0;
    transform: translate(-30px, 0);
}

[data-slideInBottom].invisible {
    opacity: 0.0;
    transform: translate(0, 30px);
}

[data-slideInTop].invisible {
    opacity: 0.0;
    transform: translate(0, -100px);
}


@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes fadein-down-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

