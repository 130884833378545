header#mainmenu {
  display: none;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: fixed;
  right: 10vw;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  @include mq(1280) {
    display: flex;
  }

  nav {
    ul.menus {
      li.menu {
        text-align: right;
        padding: 15px;
        
        a {
          font-size: 14px;
          color: #666;
          // color: white;
          font-family: 'Montserrat';
          transition: 0.5s all ease-in-out;

          &:before {
            transition: 0.5s all ease-in-out;
          }
        }
  
        &.active {
          a {
            color: #fad81b;
            position: relative;
            font-weight: 700;
            transition: 0.5s all ease-in-out;
  
            &:before {
              content: " ";
              width: 25px;
              height: 2px;
              background-color: #fad81b;
              display: block;
              position: absolute;
              top: 8px;
              transition: 0.5s all ease-in-out;
            }
          }
        }
        
        &:hover {
          a {
            color: #ccae00;
            position: relative;
            font-weight: 700;
            transition: 0.5s all ease-in-out;
  
            &:before {
              content: " ";
              width: 25px;
              height: 2px;
              background-color: #fad81b;
              display: block;
              position: absolute;
              top: 8px;
              transition: 0.5s all ease-in-out;
            }
          }
        }
      }
    }
  }

  &.top {
    nav ul.menus {
      li.menu {
        a {
          color: white;
        }

        &.active {
          a {
            color: #fad81b;
            position: relative;
            font-weight: 700;

            &:before {
              display: none;
              transition: 0.5s all ease-in-out;
            }
          }
        }

        &:hover {
          a {
            color: #cbb016;
            position: relative;
            font-weight: 700;

            &:before {
              display: none;
              transition: 0.5s all ease-in-out;
            }
          }
        }
      }
    }
  }
}
