#ranking {
  @include mq(1280) {
    height: 940px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
 
  h3 {
    margin-top: 100px;
    z-index: 1;
    position: relative;

    @include mq(1280) {
      font-size: 50px;
      line-height: 60px;
      margin-top: 150px;
    }
  }

  span.yellow-bar {
    width: 228px;
    @include mq(1280) {
      width: 430px;
      height: 22px;
      top: -30px;
    }
  }

  p {
    @include mq(1280) {
      font-size: 23px;
    }
  }

  .swiper {
    height: auto;
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;

    @include mq(1280) {
      display: none;
    }

    .swiper-slide { 
      width: 35%;
    }
  }

  .desktop-noslide {
    display: none;
    margin-top: 50px;

    @include mq(1280) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}