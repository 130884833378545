#main {
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .bgimage {
    background-image: url('../images/01_main_bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: -1;
    animation: zoomin 3s ease-in-out infinite;
  }

  nav.pager {
    ul li {
      a {
        color: white;
      }

      &.active {
        a {
          color: #fad81b;
        }
      }
    } 
  }

  img.main-logo {
    width: 84px;
    margin-bottom: 25px;
    margin-top: 20vh;
    animation: fadein-down-up 1s ease;

    @include mq(1280) {
      display: none;
    }
  }

  .text-wrapper {
    animation: fadein-down-up 1s ease;
    
    @include mq(1280) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-bottom: 50px;
      margin-top: 150px;
    }

    h3.page-title {
      color: white;
      text-align: center;
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 0px;
  
      @include mq(480) {
        font-size: 28px;
      }
  
      @include mq(1280) {
        font-size: 45px;
        margin: 0 5px;
        letter-spacing: -2px;
        font-weight: 600;
      }
    }
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    animation: fadein-down-up 1s ease;

    img.main-logo-d {
      display: none;
  
      @include mq(1280) {
        display: flex;
        width: 100px;
        margin-right: 20px;
      }
    }
  
    img.main-title {
      width: 137px;
      margin-top: 25px;
  
      @include mq(480) {
        width: 153px;
      }

      @include mq(1280) {
        width: 200px;
        margin: 0;
      }
    }
  }

  .link-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5vh;
    animation: fadein-down-up 1s ease;

    @include mq(1280) {
      flex-grow: 0;
      margin-top: 150px;
    }

    a {
      margin: 0 10px;

      .link-btn-m {
        display: flex;

        @include mq(1280) {
          display: none;
        }
      }

      .link-btn-pc {
        display: none;

        @include mq(1280) {
          display: flex;
        }
      }
    }
  }

  a.scroll {
    position: relative; 
    color: #fff;
    transition: opacity .3s;
    padding-top: 40px;
    margin-bottom: 40px;
    animation: fadein-down-up 3s ease;

    &:hover {
      opacity: 0.5;
    }
   
    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 24px;
      height: 24px;
      margin-left: -12px;
      border-left: 3px solid #fff;
      border-bottom: 3px solid #fff;
      border-radius: 2px;
      transform: rotate(-45deg);
      box-sizing: border-box;
    }
  }

}