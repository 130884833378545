#ansimdic {
  width: 100%;
  overflow: hidden;
  position: relative;

  h3 {
    margin-top: 100px;

    @include mq(1280) {
      font-size: 50px;
      line-height: 61px;
    }
  }

  span.yellow-bar {
    width: 140px;

    @include mq(1280) {
      width: 300px;
      top: -30px;
      height: 22px;
    }
  }

  p.page-desc {
    @include mq(1280) {
      font-size: 23px;
      line-height: 30px;
    }
  }

  .text-bg-wrapper {
    margin-top: 40px;
    margin-bottom: 250px;
    position: absolute;
    width: 100%;
    height: 500px;
    top: 230px;
    overflow: hidden;

    @include mq(768) {
      width: 70%;
    }

    @include mq(1280) {
      top: 330px
    }
  }

  .text-bg {
    position: absolute;
    word-break: keep-all;
    white-space: nowrap;
    left: 0;
    animation: text-stream 5s infinite;
    animation-timing-function: linear;

    span {
      color: #f5f5f5;
      line-height: 43px;
      font-size: 25px;
      font-weight: 900;
      padding: 0 5px;

      @include mq(1280) {
        font-size: 46px;
        line-height: 80px;
      }
    }

    span.big-word {
      font-size: 32px;

      @include mq(1280) {
        font-size: 51px;
        line-height: 80px;
      }
    }
  }

  @keyframes text-stream {
    0% {left: -500px;}
    100% {left: -1000px;}
  }

  .search-window {
    background-color: #131313;
    width: 284px;
    height: 57px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    z-index: 1;
    margin: 300px 0 150px;
    position: relative;
    top: -80px;

    @include mq(1280) {
      width: 461px;
      height: 94px;
      margin-bottom: 210px;
    }

    img {
      width: 23px;
      margin-left: 20px;

      @include mq(1280) {
        width: 37px;
        margin-left: 37px;
      }
    }

    span#type-text {
      font-size: 25px;
      margin-left: 10px;
      font-weight: 600;
      padding-top: 2px;

      @include mq(1280) {
        font-size: 40px;
      }

      .Typewriter__wrapper {
        color: white;
      }

      .Typewriter__cursor {
        color: white;
        margin-left: 3px;
        font-weight: lighter;
      }
    }
  }
}