.pagenotfound {
  font-size: 50px;
  color: white;
  margin-top: 150px;

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    font-size: 30px;
  }
}