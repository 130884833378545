body {
  // height: 100vh;
  // overflow: hidden;
}

main {
  // scroll-snap-type: y mandatory;
  // scroll-behavior: smooth;
  // overflow: scroll;
  // height: 100vh;

  &::-webkit-scrollbar {
    // display: none;
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // height: 100vh;
    // scroll-snap-align: start;
  }

  img {
    max-width: 100%;
  }

  h3.page-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #131313;
    text-align: center;
    line-height: 35px;
    z-index: 1;

    @include mq(480) {
      font-size: 26px;
    }
  }

  span.yellow-bar {
    background-color: #fad81b;
    width: 210px;
    height: 11px;
    position: relative;
    top: -23px;
    display: block;
  }

  p.page-desc {
    text-align: center;
    line-height: 20px;
    color: #666;
    letter-spacing: -0.5px;
  }

  .swiper {
    width: 100%;
    height: 60vh;
    text-align: center;

    .swiper-pagination-bullet-active {
      background: #fad81b;
    }
  }

}