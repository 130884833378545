#footer {
  background-color: #f6f6f6;
  padding: 50px 25px;

  .container {
    max-width: 1280px;
    margin: 0 auto;
  }

  h4 {
    font-size: 16px;
    line-height: 35px;

    img {
      width: 17px;
      position: relative;
      top: 1px;
    }
  }

  address {
    font-style: inherit;
    font-size: 14px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
  }

  .numbers {
    display: flex;
    flex-direction: column;

    @include mq(768) {
      flex-direction: row;

      p:last-child {
        margin-left: 100px;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 20px;
  }

  p.copyright {
    margin: 30px 0;
  }
}