img.header-logo-img {
  width: 66px;
  position: absolute;
  z-index: 1;
  top: 25px;
  left: 20px;

  @include mq(1280) {
    top: 60px;
    width: 141px;
    left: 160px;
  }
}

nav.pager {
  display: none;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 10vw;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  @include mq(1280) {
    // display: flex;
  }

  ul.menus {
    li.menu {
      text-align: right;
      padding: 15px;
      
      a {
        font-size: 14px;
        color: #666;
        font-family: 'Montserrat';
      }

      &.active {
        a {
          color: #fad81b;
          position: relative;
          font-weight: 700;

          &:before {
            content: " ";
            width: 25px;
            height: 2px;
            background-color: #fad81b;
            display: block;
            position: absolute;
            top: 7px;
          }
        }
      }
    }
  }
}

#main nav.pager {
  ul.menus {
    li.menu {
      a:before {
        display: none;
      }
    }
  }
}
