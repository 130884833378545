#safeinfo {
  background-color: #f6f6f6; 

  @include mq(1280) {
    flex-direction: row;
  }

  .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include mq(1280) {
      align-items: flex-start;
    }

    h3 {
      margin-top: 100px;

      @include mq(1280) {
        text-align: left;
        font-size: 50px;
        line-height: 60px;
      }
    }

    span.yellow-bar {
      @include mq(1280) {
        width: 390px;
      }
    }

    p {
      @include mq(1280) {
        text-align: left;
        font-size: 23px;
        line-height: 30px;
      }
    }
  }

  .swiper {
    height: auto;
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;

    @include mq(1280) {
      display: none;
    }

    .swiper-slide { 
      width: 62%;
      max-width: 260px;
    }
  }


  .desktop-noslide {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 50px;

    @include mq(1280) {
      display: flex;
    }
    
    .noslide {
      width: 280px;
      margin: 100px 0;
    }
  }
}