// Media queries
// Mobile First Universal
@mixin mq($point) {
  @media (min-width: #{$point}px) {
    @content;
  }
}

// Desktop First Universal
// @mixin mq($point) {
//   @media (max-width: #{$point}px) {
//     @content;
//   }
// }


// Mobile First
// @mixin mq($point) {
//   @if $point==360 {
//     @media screen and (orientation:landscape) and (min-width: 360px)  {
//       @content;
//     }
//   }
  
//   @else if $point==480 {
//     @media (min-width: 480px) {
//       @content;
//     }
//   }

//   @else if $point==768 {
//     @media (min-width: 768px) {
//       @content;
//     }
//   }

//   @else if $point==1024 {
//     @media (min-width: 1024px) {
//       @content;
//     }
//   }

//   @else if $point==1280 {
//     @media (min-width: 1280px) {
//       @content;
//     }
//   }

//   @else if $point==1600 {
//     @media (min-width: 1600px) {
//       @content;
//     }
//   }
// }

// Media queries
// Desktop First
// @mixin mq($point) {
//   @if $point==360 {
//     @media screen and (orientation:landscape) and (max-width: 360px)  {
//       @content;
//     }
//   }
  
//   @else if $point==480 {
//     @media (max-width: 480px) {
//       @content;
//     }
//   }

//   @else if $point==768 {
//     @media (max-width: 768px) {
//       @content;
//     }
//   }

//   @else if $point==1024 {
//     @media (max-width: 1024px) {
//       @content;
//     }
//   }

//   @else if $point==1280 {
//     @media (max-width: 1280px) {
//       @content;
//     }
//   }

//   @else if $point==1600 {
//     @media (max-width: 1600px) {
//       @content;
//     }
//   }
// }