#review {
  background-color: #f6f6f6;

  @include mq(768) {
    height: 1100px;
    overflow: hidden;
    justify-content: flex-start;
  }
  
  @include mq(1280) {
    height: 950px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .text-wrapper {
    @include mq(1280) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 180px;
      margin-right: 100px;
    }

    h3 {
      margin-top: 100px;
      z-index: 2;
      position: relative;

      @include mq(1280) {
        font-size: 50px;
        line-height: 60px;
        text-align: left;
      }
    }
  
    span.yellow-bar {
      width: 230px;

      @include mq(1280) {
        width: 430px;
        height: 22px;
        top: -30px;
      }
    }

    p.page-desc {
      font-size: 16px;

      @include mq(1280) {
        font-size: 23px;
      }
    }
  }


  .reviews {
    text-align: center;
    padding: 30px;
    display: flex;
    flex-direction: row;

    @include mq(1280) {
      margin-top: 120px;
    }

    .review {
      display: flex;
      flex-direction: column;
      width: 270px;
    }

    .review:not(:first-child) {
      display: none;

      @include mq(768) {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 150px;
      }
    }
  }

}