#lastpage {
  overflow: hidden;
 
  .logo-img {
    width: 109px;
    margin-top: 100px;
  }

  .cert-logos {
    margin: 50px 0;
    
    img:first-child {
      width: 54px;
    }

    img {
      width: 96px;
      margin: 0 5px;
    }
    
    img:last-child {
      width: 15px;
    }
  }

  p {
    color: #131313;
    font-size: 15px;
    font-weight: 600;
  }

  .app-links {
    margin: 20px 0 70px;
    
    a {
      margin: 10px;
    }
  }

  .bglogo1 {
    position: absolute;
    width: 115px;
    right: -20px;
    bottom: 100px;
    z-index: -1;

    @include mq(1280) {
      right: 40vw;
    }
  }

  .bglogo2 {
    position: absolute;
    width: 80px;
    left: -30px;
    bottom: -20px;
    z-index: -1;

    @include mq(1280) {
      left: 40vw;
    }
  }
}